import React from "react"
import RDStationConection from "../utils/RDStationConnection"

import Header from "../components/Header/Header"
import ArticlePage from "../components/ArticlePage/ArticlePage"
import Footer from "../components/Footer/Footer"
import NeoronWidget from "../utils/NeoronWidget"

const Article = () => {
  React.useEffect(() => {
    RDStationConection.init()
    NeoronWidget.init()
  }, [])

  return (
    <div>
      <Header />
      <ArticlePage />
      <Footer />
    </div>
  )
}

export default Article
