import React, { useEffect, useState } from "react"
import { useQueryParam, StringParam } from "use-query-params"
import Helmet from "react-helmet"

import "./articlepage.scss"

const ArticlePage = () => {
  const guidBaseURLength = "https://medium.com/p/".length
  const [id] = useQueryParam("id", StringParam)
  const [article, setArticle] = useState({
    title: "",
    pubDate: "",
    link: "",
    guid: "https://medium.com/p/1",
    author: "",
    thumbnail: "",
    description: "",
    content: "",
    enclosure: {},
    categories: [],
  })

  const months = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ]
  let article_date = new Date(article.pubDate)
  const currentYear = new Date().getFullYear()
  const formatedDate =
    article_date.getDate() +
    "." +
    months[article_date.getMonth()] +
    (currentYear !== article_date.getFullYear()
      ? "." + article_date.getFullYear()
      : "")

  useEffect(() => {
    fetch(
      "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@fabwork",
      {
        method: "GET",
      }
    ).then(response => {
      response.json().then(json => {
        setArticle(
          json.items.find(article => {
            if (article.guid.slice(guidBaseURLength) === id) return article
            return 0
          })
        )
      })
    })
  }, [guidBaseURLength, id])

  return (
    <div id="article-page">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{article.title} | FABWORK</title>
        <link rel="canonical" href={`http://fab.work/article?id=${id}`} />
      </Helmet>

      <div className="article-info">
        <h1 className="title">{article.title}</h1>
        <div className="author-info">
          <p className="author">
            Por <b>{article.author}</b>
          </p>
          <p className="date">Atualizado no dia {formatedDate}</p>
        </div>
      </div>

      <div className="article-content">
        <div
          className="article-content-html"
          dangerouslySetInnerHTML={{
            __html: article.content,
          }}
        ></div>
      </div>
    </div>
  )
}

export default ArticlePage
